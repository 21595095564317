const [red1, green1, blue1] = [226, 237, 237];
const [red2, green2, blue2] = [207, 226, 215];
const body = document.querySelector('body')

window.addEventListener('load', changeBackground);
window.addEventListener('scroll', changeBackground);

function changeBackground() {
  const diffRed = red2 - red1;
  const diffGreen = green2 - green1;
  const diffBlue = blue2 - blue1;
  const y = window.pageYOffset / (document.documentElement.scrollHeight - window.innerHeight);
  const [r, g, b] = [red1 + diffRed * y, green1 + diffGreen * y, blue1 + diffBlue * y].map(Math.round)
  body.style.backgroundColor = `rgb(${r}, ${g}, ${b})`;
}